import styled from 'styled-components';
import tw from 'tailwind.macro';

interface NavProps {
  open: boolean;
}

export const MainNavWrapper = styled.div``;

export const Nav = styled.nav<NavProps>``;

export const NavItem = styled.span``;

export const OpenNavButton = styled.button``;

export const CloseNavButton = styled(OpenNavButton)``;
