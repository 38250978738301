import React from 'react';
import {Link, useI18next} from 'gatsby-plugin-react-i18next';

import MainNav from './MainNav';
import SwitchThemeMode from './SwitchThemeMode';

import { HeaderWrapper, Title } from './styles';

interface Props {
  siteTitle: string;
}

/**
 * Header component
 *
 * @param {Props} props
 */
const Header: React.FC<Props> = ({ siteTitle }) => {

  const {languages, changeLanguage, originalPath} = useI18next();

  return (
    <div className="header">

        <div className="headline">
            <p>FRESH IS A WAY OF LIFE <span className="d-none d-lg-inline">• EXPLORE MORE IN ONE OF OUR STORES OR OUR ONLINE SHOP</span></p>
        </div>

        <MainNav />

    </div>
  );
}


Header.defaultProps = {
  siteTitle: ``
};

export default Header;
