import { ObjectType } from 'helpers/definitions';

/**
 * ----------------------------------------
 * colors
 * ----------------------------------------
 */
export const colors: ObjectType = {
  white: '#ffffff',
  black: '#000000',
  // gray
  gray100: '#F7FAFC',
  gray200: '#EDF2F7',
  gray300: '#E2E8F0',
  gray400: '#CBD5E0',
  gray500: '#A0AEC0',
  gray600: '#718096',
  gray700: '#4A5568',
  gray800: '#2D3748',
  gray900: '#1A202C'
};
