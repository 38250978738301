import React from 'react';
import { FooterWrapper } from './styles';
import Instagram from '../../assets/images/icons-insta-white.svg';
import Facebook from '../../assets/images/facebook-logo-white.svg';
import linkedin from '../../assets/images/icons-linkedin-white.svg';
import {Link, Trans, useTranslation, useI18next} from 'gatsby-plugin-react-i18next';
import parse from "html-react-parser"
import LogoMain from '../../assets/images/logo.svg';
import CookieConsent from "react-cookie-consent";

/**
 * Footer component
 */
const Footer = () => {

  const {t} = useTranslation();

    const {language, changeLanguage, originalPath} = useI18next();

  return(
      <footer className="footer mb-0">

          <div className="container">

              <div className="row">

                  <CookieConsent
                      location="bottom"
                      buttonText="Εντάξει"
                      cookieName="gatsby-gdpr-google-analytics"
                      style={{ background: "#2B373B", fontSize: '13px' }}
                      buttonStyle={{ background: '#20C19E', color: "#fff", fontSize: "13px", borderRadius: '4px' }}
                      expires={150}
                  >
                      Χρησιμοποιούμε cookies για να σας προσφέρουμε την καλύτερη δυνατή εμπειρία στη σελίδα μας. Εάν συνεχίσετε να χρησιμοποιείτε τη σελίδα, θα υποθέσουμε πως είστε ικανοποιημένοι με αυτό.{" "}
                      {/*<span style={{ fontSize: "10px" }}></span>*/}
                  </CookieConsent>

                  <div className="col-lg-4 col-sm-12">

                      <Link to={'/'} className="logo">
                          <img width="96px" height="39px" src={LogoMain} alt="" className="img-fluid"/>
                      </Link>

                      {/*UNTIL WE ACTIVATE LAGNUAGES*/}
                      {/*<Trans>{ parse(t('footer_text')) }</Trans>*/}
                      <p>Λεπτές & μοναδικές γεύσεις, γαλλικό φινίρισμα, νεωτεριστικές συσκευασίες & γλυκά που διαφέρουν είναι τα χαρακτηριστικά των ζαχαροπλαστείων Fresh.</p><p>©2020 Fresh Patisserie. All rights reserved.</p>

                  </div>

                  <div className="col pt-5 px-4 footer-padding">

                      <ul className="footer-menu">
                          <li>
                              <Link to={'/about'}>About Fresh</Link>
                          </li>
                          <li>
                              <Link to={'/privacy-policy'}>Privacy Policy</Link>
                          </li>
                          <li>
                              <Link to={'/cookie-policy'}>Cookie Policy</Link>
                          </li>
                          <li>
                              <Link to={'/terms'}>Terms & Conditions</Link>
                          </li>
                      </ul>

                  </div>

                  <div className="col pt-5 px-4 footer-padding">

                      <ul className="footer-menu">
                          <li>
                              <Link to={'/contact'}>{(language != 'en')? 'Καταστήματα' : 'Stores'}</Link>
                          </li>
                          <li>
                              <a href='https://order-freshpatisserie.gr/' target={"_blank"}>Eshop</a>
                          </li>
                          <li>
                              <Link to={'/fresh-paper'}>Fresh Paper</Link>
                          </li>
                          <li>
                              <Link to={'/events'}>Events</Link>
                          </li>
                      </ul>

                  </div>

                  <div className="col-lg-4 col-sm-12 pt-5 footer-padding">
                      <Trans>{ parse(t('newsletter')) }</Trans>
                      <div >

                          <form className="newsletter-form" action="https://order-freshpatisserie.us8.list-manage.com/subscribe/post?u=b05d327bf4032c950b68961dd&amp;id=5f4095c776" method="post" name="mc-embedded-subscribe-form" target="_blank" noValidate>
                              <input type="email" name="EMAIL" autoComplete="off" placeholder="your@email.com" />
                              <input type="submit" value="SUBSCRIBE" />
                          </form>

                      </div>
                      <ul className="social">
                          <li>
                              <a href="https://www.instagram.com/freshpatisserie/?hl=el" target="_blank">
                                  <span className={'instagramIcon'}></span>
                              </a>
                          </li>
                          <li>
                              <a href="https://www.facebook.com/freshpatisserie.gr/" target="_blank">
                                  <span border="0" className={'facebookIcon'}></span>
                              </a>
                          </li>
                          <li>
                              <a href="https://www.linkedin.com/company/freshpatisserie/?originalSubdomain=gr" target="_blank">
                                  <span border="0" className={'linkedinIcon'}></span>
                              </a>
                          </li>
                      </ul>
                  </div>

              </div>

          </div>

      </footer>
  );

};

export default Footer;
