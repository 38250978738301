import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile,
    isTablet
} from "react-device-detect";
import React, {useState, useRef, useEffect} from 'react';
import {makeStyles, createStyles} from '@material-ui/core/styles';
import {Link, useI18next} from 'gatsby-plugin-react-i18next';
import {StaticImage} from "gatsby-plugin-image";
import Img from 'gatsby-image';
import Globe from '../../../assets/images/globe.svg';
import LogoMain from '../../../assets/images/logo.svg';
import Icon from 'utils/Icon';
import {useLocation} from '@reach/router';
import {MainNavWrapper, Nav, NavItem, OpenNavButton, CloseNavButton} from './styles';
import {gsap, Expo} from 'gsap';
import {counter} from "@fortawesome/fontawesome-svg-core";

interface NavItem {
    title: string;
    slug: string;
}

const useStyles = makeStyles({
    logoHigh: {
        position: 'absolute',
        left: '50%',
        transform: 'translate(-50%, 0)'
    },
    logoLow: {
        '& a':{
            marginTop: '0'
        },
        position: 'relative',

    }
});

const MainNav: React.FC = (path) => {

    const location = useLocation();
    const {language, changeLanguage, originalPath} = useI18next();
    const [open, setOpen] = useState(false);
    const [menuOpened, setMenuOpened] = useState(false);
    const [mobileMenuCheck, setMobileMenuCheck] = useState(false);
    const [logoLarge, setLogoLarge] = useState(true);

    const prevScrollY = useRef(0);

    const [goingUp, setGoingUp] = useState(false);

    let [oldValue, setOldValue] = useState(0);
    let [newValue, setNewValue] = useState(0);
    let [counterScroll, setCounterScroll] = useState(0);
    const [matrixNumber, setMatrixNumber] = useState();
    const [X, setX] = useState((isMobile) ? 1.95 : 5);
    const [Y, setY] = useState((isMobile) ? 1.95 : 5);
    const [Z, setZ] = useState((isMobile) ? 86 : 138);
    const [playAnimation, setPlayAnimation] = useState((location.pathname === '/en/' || location.pathname === '/') ? true : false);
    const classes = useStyles();
    let boxRef = useRef();
    let logoRef = useRef();
    const navItems: NavItem[] = [
        {
            title: (language != 'en') ? 'ΚΑΤΑΣΤΗΜΑΤΑ' : 'STORES',
            slug: '/contact/'
        },
        {
            title: 'EVENTS',
            slug: '/events/'
        },
        {
            title: 'ONLINE SHOP',
            slug: 'https://order-freshpatisserie.gr/'
        }
    ];

    const navItemsRight: NavItem[] = [
        {
            title: 'VEGAN CHALLENGE',
            slug: '/veganchallenge/'
        },
        {
            title: 'FRESH PAPER & NEWS',
            slug: '/fresh-paper/'
        },
        {
            title: 'ABOUT US',
            slug: '/about/'
        }
    ];
    
    /**
     * 
     */
    const logoAnimation = React.useCallback((goingUp, currentScrollY) => {

        if(currentScrollY <= 0 ){
            if(isMobile && !isTablet){
                setMatrixNumber('matrix(1.95, 0, 0, 1.95, 0, 86)');
                setX(1.95);
                setY(1.95);
                setZ(86);
            }else{
                setMatrixNumber('matrix(5, 0, 0, 5, 0, 138)');
                setX(5);
                setY(5);
                setZ(144);
            }
        }else{
            if(!goingUp){

                if(isMobile && !isTablet) {

                    if( Z < 0 ){
                        setMatrixNumber('matrix(1, 0, 0, 1, 0, 0)');
                        setX(1);
                        setY(1);
                        setZ(0);
                    }else{
                        let Xv = X - 0.20;
                        let Yv = Y - 0.20;
                        let Zv = Z - 25;
                        setX(Xv);
                        setY(Yv);
                        setZ(Zv);
                        setMatrixNumber(`matrix(${X}, 0, 0, ${Y}, 0, ${Z})`);
                    }
                }else{
                    if(Z < -9){
                        setMatrixNumber('matrix(1, 0, 0, 1, 0, 0)');
                        setX(1);
                        setY(1);
                        setZ(0);
                    }else{
                        let Xv = X - 0.65;
                        let Yv = Y - 0.65;
                        let Zv = Z - 25;
                        setX(Xv);
                        setY(Yv);
                        setZ(Zv);
                        setMatrixNumber(`matrix(${X}, 0, 0, ${Y}, 0, ${Z})`);
                    }
                }

            }else{

                if(isMobile && !isTablet) {
                    if(currentScrollY < 20 && Z < 90){
                        let Xv = X + 0.25;
                        let Yv = Y + 0.25;
                        let Zv = Z + 25;
                        setX(Xv);
                        setY(Yv);
                        setZ(Zv);
                        setMatrixNumber(`matrix(${X}, 0, 0, ${Y}, 0, ${Z})`);
                    }
                }else{
                    if(currentScrollY < 20 && Z < 150){
                        let Xv = X + 0.65;
                        let Yv = Y + 0.65;
                        let Zv = Z + 25;
                        setX(Xv);
                        setY(Yv);
                        setZ(Zv);
                        setMatrixNumber(`matrix(${X}, 0, 0, ${Y}, 0, ${Z})`);
                    }
                }
                
            }

        }
        
    }, [ newValue, oldValue, X, Y, Z, matrixNumber ]);

    // useEffect(() => {
    //     window.addEventListener('scroll', function () {
    //         logoAnimation();
    //     });
    // }, []);

    useEffect(() => {

        window.scrollTo(0, 0);

        if( isMobile && !isTablet ){
            setMatrixNumber('matrix(1.95, 0, 0, 1.95, 0, 86)');
        }else{
            setMatrixNumber('matrix(5, 0, 0, 5, 0, 138)');
        }

    },[]);

    useEffect(() => {

        if(originalPath === '/'){
            const handleScroll = () => {

                const currentScrollY = window.scrollY;

                if (prevScrollY.current < currentScrollY && goingUp) {
                    setGoingUp(false);

                }
                if (prevScrollY.current > currentScrollY && !goingUp) {
                    setGoingUp(true);
                }

                prevScrollY.current = currentScrollY;

                logoAnimation(goingUp, currentScrollY);

            };

            window.addEventListener("scroll", handleScroll, { passive: true });

            return () => window.removeEventListener("scroll", handleScroll);

        }else{
            setMatrixNumber('matrix(1, 0, 0, 1, 0, 0)');
        }

     });

    const mobileMenu = (e) => {

        e.preventDefault();

        setMenuOpened(true);

        (mobileMenuCheck) ? setMobileMenuCheck(false) : setMobileMenuCheck(true);

        //  Check Menu
        if(mobileMenuCheck){
            // setLogoLarge(false);
            // gsap.to([boxRef.current], {
            //     duration: 0.3,
            //     scale: 1,
            //     top: 0,
            //     ease: Expo.easeOut
            // });

        }

    }

    return (
        <div className="container-fluid">

            <div className="row align-items-center">

                <div className="col-5">
                    <ul className="main-menu left-menu d-none d-lg-block">
                        {navItems.map((item, index) => (
                            <li key={'menu' + index}>
                                <Link to={item.slug}>
                                    {item.title}
                                </Link>
                            </li>
                        ))}

                    </ul>

                    <ul className="main-menu left-menu mobile-only">
                        {
                            (language !== 'en')
                                ?
                                <li>
                                    <Link to={originalPath} language={'en'}>
                                        EN
                                        <span className="svg-icon">
                    <img style={{display: 'inline'}} src={Globe} alt="" className="img-fluid"/>
                  </span>
                                    </Link>
                                </li>
                                :
                                <li>
                                    <Link to={originalPath} language={'el'}>
                                        ΕΛ
                                        <span className="svg-icon">
                    <img style={{display: 'inline'}} src={Globe} alt="" className="img-fluid"/>
                  </span>
                                    </Link>
                                </li>
                        }
                    </ul>

                </div>

                {/* LOGO */}
                <div style={{position: 'relative'}} className="col-2 text-center" ref={boxRef}>

                    {
                        (originalPath === '/')
                            ? <img id="logoContain" style={{ transform: `${matrixNumber}`, display: 'block', marginRight: 'auto', marginLeft: 'auto', width: '79px'}} src={LogoMain} alt="" />
                            : <a href = {'/'}>
                                <img id="logoContain" style={{ transform: `${matrixNumber}`, display: 'block', marginRight: 'auto', marginLeft: 'auto', width: '79px'}} src={LogoMain} alt="" />
                            </a>

                    }

                </div>

                <div style={{ zIndex: 999}} className="col-5 text-end">

                    <ul className="main-menu right-menu d-none d-lg-block">

                        {navItemsRight.map((item, index) => (
                            <li key={'menuRight' + index}>
                                <Link to={item.slug}>
                                    {item.title}
                                </Link>
                            </li>
                        ))}

                        {
                            (language !== 'en')
                                ?
                                <li>
                                    <Link className="all-icon" to={originalPath} language={'en'}>
                                        EN
                                        <div className="svg-icon">
                                            <span className="globe"></span>
                                        </div>
                                    </Link>
                                </li>
                                :
                                <li>
                                    <Link className="all-icon" to={originalPath} language={'el'}>
                                        ΕΛ
                                        <div className="svg-icon">
                                            <span className="globe"></span>
                                        </div>
                                    </Link>
                                </li>
                        }

                    </ul>

                    <a href={''} onClick={(e) => {
                        mobileMenu(e)
                    }} className={`burger ${(mobileMenuCheck) ? 'active' : ''}`}>
                        <div className="text">
                            MENU
                        </div>
                        <div className="icon">
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                    </a>

                </div>

            </div>

            <ul className={`mobimenu ${(mobileMenuCheck) ? 'active' : ''}`}>

                {navItems.map((item, index) => (
                    <li key={'menu' + index}>
                        <Link to={item.slug}>
                            {item.title}
                        </Link>
                    </li>
                ))}

                {navItemsRight.map((item, index) => (
                    <li key={'menuRight' + index}>
                        <Link to={item.slug}>
                            {item.title}
                        </Link>
                    </li>
                ))}

                <li><a href="https://www.facebook.com/freshpatisserie.gr">FACEBOOK</a></li>
                <li><a href="https://www.instagram.com/freshpatisserie/?hl=el">INSTAGRAM</a></li>
                <li><a href="https://www.linkedin.com/company/freshpatisserie/?originalSubdomain=gr">LINKEDIN</a></li>
            </ul>

        </div>
        // <MainNavWrapper>
        //   <OpenNavButton onClick={() => setOpen(true)}>
        //     <Icon icon="bars" />
        //   </OpenNavButton>
        //   <Nav open={open}>
        //     <CloseNavButton onClick={() => setOpen(false)}>
        //       <Icon icon="times" />
        //     </CloseNavButton>
        //     {navItems.map((item, index) => (
        //       <NavItem key={`nav-item-${index}`}>
        //         <Link to={item.slug} activeClassName="active">
        //           {item.title}
        //         </Link>
        //       </NavItem>
        //     ))}
        //   </Nav>
        // </MainNavWrapper>
    );
};

export default MainNav;